const Loading = () => (
  <div className="loader">
    <p>Processando informações</p>
    <div className="loader1">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
)

export default Loading
