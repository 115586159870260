export const subscriptions = (_, offer) => {
  switch (offer) {
    case 'OFF2':
      return {
        data: {
          data: {
            offer_code: 'OFF2',
            offer_name: 'Teste Anual com parcelas',
            offer_price: 99700,
            offer_initial_price: 0,
            offer_initial_period: null,
            offer_initial_interval: null,
            offer_period: 1,
            offer_interval: 'ano',
            offer_trial_days: 7,
            installments: [
              {
                installments: 1,
                label: '1 x R$ 997,00',
              },
              {
                installments: 2,
                label: '2 x R$ 528,31',
              },
              {
                installments: 3,
                label: '3 x R$ 362,14',
              },
              {
                installments: 6,
                label: '6 x R$ 195,98',
              },
              {
                installments: 9,
                label: '9 x R$ 140,59',
              },
              {
                installments: 12,
                label: '12 x R$ 112,89',
              },
            ],
            plan: {
              name: 'Platinum',
              features: [
                {
                  name: 'Testes A/B',
                  value: 'Y',
                },
                {
                  name: 'Analytics',
                  value: 'Y',
                },
                {
                  name: 'Dom\u00ednios',
                  value: '6',
                },
                {
                  name: 'Funis',
                  value: 'U',
                },
                {
                  name: 'P\u00e1ginas',
                  value: 'U',
                },
                {
                  name: 'Redirecionamentos (join-zap)',
                  value: 'Y',
                },
                {
                  name: 'Limite de tr\u00e1fego',
                  value: '250000',
                },
              ],
            },
            custom_offers: [
              {
                code: 'ORDERBUMP03',
                title: 'Oferta Limitada 03',
                price: 4000,
                type: 'order_bump',
                description: 'Essa oferta e por tempo limitado',
                image_url: 'http://via.placeholder.com/640x360',
                button_text: 'Adicionar ao pedido!',
                installments: [
                  {
                    installments: 1,
                    label: '1 x R$ 40,00',
                  },
                  {
                    installments: 2,
                    label: '2 x R$ 21,20',
                  },
                  {
                    installments: 3,
                    label: '3 x R$ 14,53',
                  },
                  {
                    installments: 6,
                    label: '6 x R$ 7,86',
                  },
                  {
                    installments: 9,
                    label: '9 x R$ 5,64',
                  },
                ],
              },
              {
                code: 'ORDERBUMP04',
                title: 'Oferta Limitada 04',
                price: 4000,
                type: 'order_bump',
                description: 'Essa oferta e por tempo limitado',
                image_url: 'http://via.placeholder.com/640x360',
                button_text: 'Adicionar ao pedido!',
                installments: [],
              },
              {
                code: 'ORDERBUMP05',
                title: 'Oferta Limitada 05',
                price: 5000,
                type: 'order_bump',
                description: 'Essa oferta e por tempo limitado',
                image_url: 'http://via.placeholder.com/640x360',
                button_text: 'Adicionar ao pedido!',
                installments: [],
              },
              {
                code: 'ORDERBUMP06',
                title: 'Oferta Limitada 06',
                price: 4000,
                type: 'order_bump',
                description: 'Essa oferta e por tempo limitado',
                image_url: 'http://via.placeholder.com/640x360',
                button_text: 'Adicionar ao pedido!',
                installments: [],
              },
            ],
          },
        },
      }
    case 'PLATINUM':
      return {
        data: {
          data: {
            offer_code: 'PLATINUM',
            offer_name: 'Platinum',
            offer_price: 12700,
            offer_initial_price: 0,
            offer_initial_period: null,
            offer_initial_interval: null,
            offer_period: 1,
            offer_interval: 'm\u00eas',
            offer_trial_days: 7,
            installments: ['1 x R$ 127,00'],
            plan: {
              name: 'Platinum',
              features: [
                {
                  name: 'Analytics das P\u00e1ginas',
                  value: 'Y',
                },
                {
                  name: 'Dom\u00ednios',
                  value: '6',
                },
                {
                  name: 'Funis',
                  value: 'U',
                },
                {
                  name: 'P\u00e1ginas',
                  value: 'U',
                },
                {
                  name: 'Links Redirecion\u00e1veis ilimitados',
                  value: 'Y',
                },
                {
                  name: 'Visitas nos sites por m\u00eas',
                  value: '150000',
                },
              ],
            },
            custom_offers: [],
          },
        },
      }
    case 'PLATESPECIAL':
      return {
        data: {
          data: {
            offer_code: 'PLATESPECIAL',
            offer_name: 'Platinum Oferta Especial',
            offer_price: 12700,
            offer_initial_price: 100,
            offer_initial_period: 1,
            offer_initial_interval: 'month',
            offer_period: 1,
            offer_interval: 'm\u00eas',
            offer_trial_days: 0,
            installments: ['1 x R$ 127,00'],
            plan: {
              name: 'Platinum',
              features: [
                {
                  name: 'Analytics das P\u00e1ginas',
                  value: 'Y',
                },
                {
                  name: 'Dom\u00ednios',
                  value: '6',
                },
                {
                  name: 'Funis',
                  value: 'U',
                },
                {
                  name: 'P\u00e1ginas',
                  value: 'U',
                },
                {
                  name: 'Links Redirecion\u00e1veis ilimitados',
                  value: 'Y',
                },
                {
                  name: 'Visitas nos sites por m\u00eas',
                  value: '150000',
                },
              ],
            },
            custom_offers: [],
          },
        },
      }
    case 'PLATESPECIALANUAL':
      return {
        data: {
          data: {
            offer_code: 'PLATESPECIALANUAL',
            offer_name: 'Platinum Anual - Especial',
            offer_price: 99700,
            offer_initial_price: 0,
            offer_initial_period: null,
            offer_initial_interval: null,
            offer_period: 1,
            offer_interval: 'ano',
            offer_trial_days: 0,
            installments: [
              {
                installments: 1,
                label: '1 x R$ 997,00',
              },
              {
                installments: 2,
                label: '2 x R$ 515,15',
              },
              {
                installments: 3,
                label: '3 x R$ 348,98',
              },
              {
                installments: 6,
                label: '6 x R$ 182,82',
              },
              {
                installments: 9,
                label: '9 x R$ 127,43',
              },
              {
                installments: 12,
                label: '12 x R$ 99,73',
              },
            ],
            plan: {
              name: 'Platinum',
              features: [
                {
                  name: 'Analytics das P\u00e1ginas',
                  value: 'Y',
                },
                {
                  name: 'Dom\u00ednios',
                  value: '6',
                },
                {
                  name: 'Funis',
                  value: 'U',
                },
                {
                  name: 'P\u00e1ginas',
                  value: 'U',
                },
                {
                  name: 'Links Redirecion\u00e1veis ilimitados',
                  value: 'Y',
                },
                {
                  name: 'Visitas nos sites por m\u00eas',
                  value: '150000',
                },
              ],
            },
            custom_offers: [],
          },
        },
      }
    case 'DIAMOND':
      return {
        data: {
          data: {
            offer_code: 'DIAMOND',
            offer_name: 'Diamond',
            offer_price: 39700,
            offer_initial_price: 0,
            offer_initial_period: null,
            offer_initial_interval: null,
            offer_period: 1,
            offer_interval: 'm\u00eas',
            offer_trial_days: 7,
            installments: ['1 x R$ 397,00'],
            plan: {
              name: 'Diamond',
              features: [
                {
                  name: 'Analytics',
                  value: 'Y',
                },
                {
                  name: 'Dom\u00ednios',
                  value: '10',
                },
                {
                  name: 'Funis',
                  value: 'U',
                },
                {
                  name: 'P\u00e1ginas',
                  value: 'U',
                },
                {
                  name: 'Redirecionamentos',
                  value: 'Y',
                },
                {
                  name: 'Visitas nos sites por m\u00eas',
                  value: '500000',
                },
              ],
            },
            custom_offers: [],
          },
        },
      }
    case 'ENTRADAIRRESISTIVEL':
      return {
        data: {
          data: {
            offer_code: 'ENTRADAIRRESISTIVEL',
            offer_name: 'Platinum Oferta Especial + Entrada Irresist\u00edvel',
            offer_price: 9700,
            offer_initial_price: 1900,
            offer_initial_period: 1,
            offer_initial_interval: 'month',
            offer_period: 1,
            offer_interval: 'm\u00eas',
            offer_trial_days: 0,
            installments: [],
            plan: {
              name: 'Platinum',
              features: [
                {
                  name: 'Analytics das P\u00e1ginas',
                  value: 'Y',
                },
                {
                  name: 'Dom\u00ednios',
                  value: '6',
                },
                {
                  name: 'Funis',
                  value: 'U',
                },
                {
                  name: 'P\u00e1ginas',
                  value: 'U',
                },
                {
                  name: 'Links Redirecion\u00e1veis ilimitados',
                  value: 'Y',
                },
                {
                  name: 'Visitas nos sites por m\u00eas',
                  value: '150000',
                },
              ],
            },
            custom_offers: [
              {
                code: 'ODERBUMPTRILOGIARUSSELLBRUNSON',
                title: 'Trilogia do Russell Brunson',
                price: 2700,
                type: 'order_bump',
                description:
                  '<strong>1 milh\u00e3o de d\u00f3lares de faturamento mensal.</strong> Aprenda o passo a passo das estrat\u00e9gias criadas pelo maior empreendedor digital americano respons\u00e1vel por um faturamento de 7 d\u00edgitos mensais nas suas empresas atrav\u00e9s da internet. (mais de 13h de conte\u00fado)',
                image_url: null,
                button_text: 'Quero esta oferta',
                installments: [
                  {
                    installments: 1,
                    label: '1 x R$ 27,00',
                  },
                  {
                    installments: 2,
                    label: '2 x R$ 13,95',
                  },
                  {
                    installments: 3,
                    label: '3 x R$ 9,45',
                  },
                  {
                    installments: 4,
                    label: '4 x R$ 7,20',
                  },
                  {
                    installments: 5,
                    label: '5 x R$ 5,85',
                  },
                ],
              },
              {
                code: 'ODERBUMPTRILOGIACOPYRIGHT',
                title: 'Trilogia de Copywriting',
                price: 3700,
                type: 'order_bump',
                description:
                  '<strong>Palavras que vendem milh\u00f5es.</strong> Aprenda as t\u00e9cnicas comprovadas de escrita persuasiva com os melhores copywriters americanos que escreverem cartas de vendas que faturam milh\u00f5es no mercado mais concorrido do mundo. (mais de 10h de conte\u00fado)',
                image_url: null,
                button_text: 'Quero esta oferta',
                installments: [
                  {
                    installments: 1,
                    label: '1 x R$ 37,00',
                  },
                  {
                    installments: 2,
                    label: '2 x R$ 19,12',
                  },
                  {
                    installments: 3,
                    label: '3 x R$ 12,95',
                  },
                  {
                    installments: 4,
                    label: '4 x R$ 9,87',
                  },
                  {
                    installments: 5,
                    label: '5 x R$ 8,02',
                  },
                ],
              },
            ],
          },
        },
      }
    case 'PRESEMESP':
      return {
        data: {
          data: {
            custom_offers: [],
            installments: [
              {
                installments: 1,
                label: '1 x R$ 497,00',
              },
              {
                installments: 2,
                label: '2 x R$ 256,80',
              },
              {
                installments: 3,
                label: '3 x R$ 173,97',
              },
              {
                installments: 6,
                label: '6 x R$ 91,13',
              },
            ],
            offer_code: 'PRESEMESP',
            offer_initial_interval: null,
            offer_initial_period: null,
            offer_initial_price: 0,
            offer_interval: 'mês',
            offer_name: 'Premium Semestral - Especial',
            offer_period: 6,
            offer_price: 49700,
            offer_trial_days: 0,
            plan: {
              features: [
                {
                  name: 'Analytics das Páginas',
                  value: 'Y',
                },
                {
                  name: 'Domínios',
                  value: '6',
                },
                {
                  name: 'Funis',
                  value: 'U',
                },
                {
                  name: 'Páginas',
                  value: 'U',
                },
                {
                  name: 'Links Redirecionáveis ilimitados',
                  value: 'Y',
                },
                {
                  name: 'Visitas nos sites por mês',
                  value: '150000',
                },
              ],
              name: 'Platinum',
            },
          },
        },
      }
    case 'PREMIUM':
      return {
        data: {
          data: {
            "custom_offers": [],
            "installments": [],
            "offer_code": "PREMIUM",
            "offer_initial_interval": null,
            "offer_initial_period": null,
            "offer_initial_price": 0,
            "offer_interval": "mês",
            "offer_name": "Plano Premium Mensal",
            "offer_period": 1,
            "offer_price": 12700,
            "offer_trial_days": 0,
            "plan": {
              "features": [
                {
                  "name": "Analytics",
                  "slug": "analytics",
                  "value": "Y"
                },
                {
                  "name": "Domínios",
                  "slug": "domains",
                  "value": "6"
                },
                {
                  "name": "Funis",
                  "slug": "funnels",
                  "value": "U"
                },
                {
                  "name": "Páginas",
                  "slug": "pages",
                  "value": "U"
                },
                {
                  "name": "Redirecionamentos",
                  "slug": "redirection-pages",
                  "value": "Y"
                },
                {
                  "name": "Limite de tráfego",
                  "slug": "visits-per-month",
                  "value": "100000"
                }
              ],
              "name": "Premium"
            }
          }
        },
      }
    case 'START-ANUAL':
      return {
        data: {
          "data": {
            "offer_code": "STARTER-ANUAL",
            "offer_name": "Anual Starter",
            "offer_price": 59900,
            "offer_initial_price": 0,
            "offer_initial_period": 0,
            "offer_initial_interval": "",
            "offer_period": 1,
            "offer_interval": "ano",
            "offer_trial_days": 0,
            "installments": [
              {
                "installments": 1,
                "label": "1 x R$ 599,00"
              },
              {
                "installments": 2,
                "label": "2 x R$ 309,50"
              },
              {
                "installments": 3,
                "label": "3 x R$ 209,67"
              },
              {
                "installments": 6,
                "label": "6 x R$ 109,84"
              },
              {
                "installments": 9,
                "label": "9 x R$ 76,56"
              },
              {
                "installments": 12,
                "label": "12 x R$ 59,92"
              }
            ],
            "plan": {
              "name": "Starter",
              "features": [
                {
                  "name": "Analytics",
                  "slug": "analytics",
                  "value": "Y"
                },
                {
                  "name": "Dominios",
                  "slug": "domains",
                  "value": "2"
                },
                {
                  "name": "Funnel Canvas",
                  "slug": "funnel-canvas",
                  "value": "U"
                },
                {
                  "name": "Funis",
                  "slug": "funnels",
                  "value": "U"
                },
                {
                  "name": "Páginas",
                  "slug": "pages",
                  "value": "20"
                },
                {
                  "name": "Redirecionamentos",
                  "slug": "redirection-pages",
                  "value": "Y"
                },
                {
                  "name": "Limite de tráfego",
                  "slug": "visits-per-month",
                  "value": "15000"
                }
              ]
            },
            "custom_offers": []
          }
        }
      }
    default:
      return {
        data: {
          data: {
            offer_code: 'OFF0',
            offer_name: 'Platinum',
            offer_price: 9799,
            offer_initial_price: 100,
            offer_initial_interval: 'month',
            offer_initial_period: 1,
            offer_interval: 'mês',
            offer_period: 1,
            offer_trial_days: 7,
            installments: ['1 x R$ 100,00'],
            plan: {
              name: 'Plan 1',
              features: [
                {
                  name: 'Feature 1',
                  value: '1',
                },
              ],
            },
            custom_offers: [],
          },
        },
      }
  }
}

export const getProfile = () => ({ data: { data: { name: 'Tester', phone_ddd: '85', phone_number: '912341234', email: 'testador@test.com' } } })