import PropTypes from 'prop-types'
import React from 'react'
import { Box, Flex, Text } from 'rebass'
import { Disclaimer } from '../Form/styles'

// import { Container } from './styles';

function OrderDetails({
  title,
  priceOFFer,
  price,
  offerInterval,
  offerPeriod,
  disclaimer,
  hasOfferOrTrial = false,
}) {
  const plural = interval => (interval === 'ano' ? 'anos' : 'meses')
  const interval = offerPeriod > 1 ? plural(offerInterval) : offerInterval

  const renderPrice = () => {
    let base = 'Preço'

    if (offerInterval) base += ` por ${offerPeriod} ${interval}`
    return base
  }

  const renderPeriodicity = () => {
    if (offerPeriod > 1) return `R$ ${price}  a cada ${offerPeriod} ${interval}`
    return `R$ ${price} / ${offerInterval}`
  }

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          backgroundColor: '#f0f3f7',
          fontSize: '14px',
          lineHeight: '1.71',
          fontWeight: 700,
          padding: '15px 20px',
          marginTop: '25px',
          textAlign: 'left',
          color: '#616877',
        }}
      >
        {title}
      </Box>
      <Box
        sx={{
          fontSize: '15px',
          lineHeight: 1.5,
          borderBottom: '1px dotted #d0d2d8',
          padding: '5px 20px',
          marginBottom: '2.5rem',
          color: '#616877',
        }}
      >
        <Flex justifyContent={'space-between'} sx={{ color: '#616877' }}>
          {!hasOfferOrTrial && <Text>{renderPrice()}</Text>}
          {priceOFFer ? priceOFFer : ''}
        </Flex>
        {disclaimer && (
          <Flex justifyContent={'space-between'} sx={{ color: '#616877' }}>
            <Disclaimer>
              <Text
                fontSize={1}
                fontWeight="normal"
                color="secondary"
                style={{
                  color: '#52575C',
                  opacity: 0.65,
                  fontSize: 14,
                  fontFamily: 'Nunito Sans',
                  cursor: 'default',
                }}
              >
                Após este período, será cobrado o valor de {renderPeriodicity()}
              </Text>
            </Disclaimer>
          </Flex>
        )}
      </Box>
    </>
  )
}

OrderDetails.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  offerPrice: PropTypes.string,
  hasOfferOrTrial: PropTypes.bool,
}

export default OrderDetails
