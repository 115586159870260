import { Select } from '@rebass/forms'
import React from 'react'
import { Box } from 'rebass'

const InputSelect = React.forwardRef(
  ({ name, handleChange, options, ...rest }, ref) => {
    // const optionsNormalize = () => {
    //   if(Object.keys(options).length === 0) return null

    //   return Object.keys(options).map(key => ({
    //     id: key,
    //     value: options[key]
    //   }))
    // }
    // const optionsValueList = optionsNormalize()

    return (
      <Box>
        <Select
          ref={ref}
          id={name}
          name={name}
          defaultValue={'1'}
          onChange={handleChange}
          style={{
            border: '2px solid #EDF0F7',
            borderRadius: 8,
            fontFamily: 'Nunito Sans',
            color: '#52575C',
            fontSize: 14,
            margin: '15px 0px 5px 0px',
          }}
          {...rest}
        >
          <option value="-99" disabled>
            Selecione uma parcela
          </option>
          {options.length &&
            options.map((item, index) => (
              <option
                key={`${item.installments}_${index}`}
                value={item.installments}
              >
                {item.label}
              </option>
            ))}
        </Select>
      </Box>
    )
  },
)

export default InputSelect
