import * as React from 'react'
import InputAdornment from '@mui/material/InputAdornment'
import {Box} from 'rebass'
import {Input} from '@mui/material'
import CreditCardIcon from '../assets/card.svg'
import InputMask from 'react-input-mask'

export default function InputGroup({
  name,
  placeholder,
  handleChange,
  type,
  mask,
  placeholderMask,
}) {
  return (
    <Box width={'100%'}>
      <InputMask
        mask={mask}
        onChange={handleChange}
        maskPlaceholder={placeholderMask ? placeholderMask : null}
      >
        <Input
          id={name}
          name={name}
          placeholder={placeholder}
          onChange={handleChange}
          type={type}
          disableUnderline
          required
          sx={{
            border: '2px solid #EDF0F7',
            borderRadius: '8px',
            fontFamily: 'Nunito Sans',
            fontSize: '15px',
            margin: '15px 0px 0px',
            padding: '8px',
            paddingLeft: '20px',
            '& input': {
              padding: '0px',
              '&::-webkit-input-placeholder': {
                opacity: 1,
                color: '#52575C',
              },
            },
          }}
          fullWidth
          startAdornment={
            <InputAdornment position="start">
              <img src={CreditCardIcon} alt="credit-card" />
            </InputAdornment>
          }
        />
      </InputMask>
    </Box>
  )
}
