import axios from 'axios'
import { useEffect, useRef, useState } from 'react'

export const useZipCodes = () => {
  const [showCepDetails, setShowCepDetails] = useState(false)
  const [cepNotFonded, setCepNotFonded] = useState(null)
  const [address, setAddress] = useState(null)

  const getZipCode = zipCode => {
    setCepNotFonded(null)
    axios({
      method: 'get',
      url: `https://api.pagar.me/1/zipcodes/${zipCode}`,
    })
      .then(response => {
        const { neighborhood, street } = response.data

        setShowCepDetails(true)
        setAddress({
          cus_street: street,
          cus_neighborhood: neighborhood,
        })
      })
      .catch(error => {
        setShowCepDetails(false)
        setCepNotFonded(error.response?.data?.errors[0].message)
      })
  }

  return { address, showCepDetails, cepNotFonded, getZipCode }
}

export const useCheckoutState = () => {
  const [token, setToken] = useState(false)

  const shoppingCart = ({ offer, customer_email, customer_name }) => {
    if (!customer_email) return
    let data = {
      offer, // Obrigatório deve ser uma oferta válida
      customer_email, // obrigatório email
      event: 'init-cart', // obrigatório 'init-cart' ou 'update-cart',
    }
    if (customer_name) data.customer_name = customer_name // opcional,
    if (token) {
      data = {
        ...data,
        event: 'update-cart',
        token,
      }
    }

    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}/ws/checkout/event`,
      data,
    })
      .then(response => {
        const { token } = response.data
        setToken(token)
      })
      .catch(error => {
        console.error(error)
      })
  }

  return { shoppingCart }
}

export const useCaptchaCloudflare = (plan) => {
  const responseToken = useRef(null);

  useEffect(() => {
    const setupTurnstile = function () {
      window?.turnstile?.render('#turnstileEl', {
        sitekey: '0x4AAAAAAABAWSAKmrFjPDF4',
        callback: function (token) {
          responseToken.current = token;
        },
        'error-callback': function (err) {
          console.log(`Error ${err}`);
        }
      });
    };
    window.onloadTurnstileCallback = setupTurnstile

    setupTurnstile()
  }, [plan])

  return {
    cfToken: responseToken.current,
  }
}
