import ForwardIcon from '@mui/icons-material/Forward'
import { Checkbox, FormGroup } from '@mui/material'
import { styled } from '@mui/material/styles'
import DOMPurify from 'dompurify'
import React from 'react'
import { Box, Flex, Text } from 'rebass'
import { normalizePrice } from '../Form/utils'
import InputSelect from '../Select'
import { Content, Title, Wrapper } from './styles'

const CheckboxBase = styled(Checkbox)(() => ({
  svg: {
    color: '#54C989',
  },
}))

const InstallmentsSelect = ({
  customOffer,
  customOffersSelected,
  handleChangeCheckbox,
  handleChangeOrderBumpInstallments,
}) => {
  const offerSelected = customOffersSelected.findIndex(
    el => el?.code === customOffer.code,
  )
  const InputRef = React.useRef()
  const hasOneInstallment = Object.keys(customOffer.installments).length <= 1

  const renderCTA = () => {
    let base = customOffer.button_text

    if (hasOneInstallment)
      base += ` R$ ${normalizePrice(customOffer.price / 100)}`

    return base
  }
  debugger
  return (
    <Box
      sx={{
        borderRadius: '0.3rem',
        boxShadow: '0px 4px #d5d5d5',
        border: '1px solid #d5d5d5',
        flex: 1,
      }}
    >
      <Flex
        justifyContent={'start'}
        alignItems={'center'}
        flex={1}
        sx={{
          borderBottom: '1px solid #d5d5d5',
        }}
      >
        <CheckboxBase
          id={customOffer.code}
          name={customOffer.code}
          onChange={e => handleChangeCheckbox(e, InputRef)}
        />
        <Text sx={{ fontWeight: 'bold' }}>{renderCTA()}</Text>
      </Flex>
      <Box
        p={2}
        sx={{
          display: hasOneInstallment ? 'none' : 'block',
        }}
      >
        <InputSelect
          ref={InputRef}
          options={customOffer.installments}
          name={customOffer.code}
          handleChange={handleChangeOrderBumpInstallments}
          disabled={offerSelected === -1}
        // value={hasOneInstallment ? 1 : customOffersSelected[offerSelected]?.installments}
        />
        <Text
          fontSize={1}
          fontWeight="normal"
          color="secondary"
          style={{
            color: '#52575C',
            opacity: 0.65,
            fontSize: 13,
            fontFamily: 'Nunito Sans',
            cursor: 'default',
            fontStyle: 'italic'
          }}
        >*Pagamento parcelado com acréscimo de juros.</Text>
      </Box>
    </Box>
  )
}

function OrderBump({ plan, ...rest }) {
  return (
    <FormGroup>
      {plan.custom_offers.map((customOffer, index) => (
        <Wrapper key={`Order_${index}_${customOffer.code}`}>
          <Title sx={{ fontWeight: 'bold' }}>{customOffer.title}</Title>
          <Content>
            <Flex justifyContent={'start'} alignItems={'center'} pb={3}>
              <ForwardIcon
                sx={{
                  color: 'red',
                  fontSize: '2.5rem',
                }}
              />
              <InstallmentsSelect customOffer={customOffer} {...rest} />
            </Flex>
            <Flex
              justifyContent={'flex-start'}
              alignItems={'flex-start'}
              flexWrap={'nowrap'}
            >
              {customOffer.image_url && (
                <Box flex={0.5} p={1} pr={3}>
                  <img
                    style={{ width: '100%' }}
                    src={customOffer.image_url}
                    alt="Product"
                    loading="lazy"
                  />
                </Box>
              )}
              <Box flex={1}>
                <Text
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(customOffer.description),
                  }}
                />
              </Box>
            </Flex>
          </Content>
        </Wrapper>
      ))}
    </FormGroup>
  )
}

export default OrderBump
