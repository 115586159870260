import React, { useCallback, useState } from 'react'
import { Box, Button, Flex, Text } from 'rebass'
import { ReactComponent as CardIcon } from '../../assets/cards.svg'
import { useInitializeForm } from '../../hooks'
import InputForm, { InputFormMask } from '../InputForm'
import InputGroup from '../InputGroup'
import LabelForm from '../Label'
import OrderBump from '../OrderBump'
import OrderDetails from '../OrderDetails'
import InputSelect from '../Select'
import { useCaptchaCloudflare, useCheckoutState, useZipCodes } from './hooks'
import { Content } from './styles'
import {
  getCustomOffer,
  getCustomOfferIdByCode,
  orderDetailsPrice,
  renderPrice,
  renderPriceOffer
} from './utils'

const initialStateForm = {
  cus_name: '',
  cus_document: '',
  cus_email: '',
  cus_phone_ddd: '',
  cus_phone_number: '',
  cus_street: '',
  cus_street_number: '',
  cus_neighborhood: '',
  cus_zipcode: '',
  cus_complementary: '',
  card_holder_name: '',
  card_expiration_date: '',
  card_number: '',
  card_cvv: '',
  installments: 1,
  custom_offers: [],
  terms_of_use: true,
}

function Form({
  plan,
  installmentSelected,
  submit,
  getPromo,
  setPromoCode,
  promo,
  profile
}) {
  const { cfToken } = useCaptchaCloudflare(plan)
  const initializedFormWithParams = useInitializeForm({ profile, initialState: initialStateForm })
  const [form, setForm] = useState(initializedFormWithParams)
  const [isCupomVisible, setIsCupomVisible] = useState(true)
  const { address, showCepDetails, cepNotFonded, getZipCode } = useZipCodes()
  const { shoppingCart } = useCheckoutState()

  React.useEffect(() => {
    setForm({
      ...form,
      installments: installmentSelected.current || 1,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installmentSelected.current])

  React.useEffect(() => {
    if (!address) return
    setForm({
      ...form,
      ...address,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address])

  const handleSubmit = e => {
    e.preventDefault()
    submit({
      ...form,
      'cf-turnstile-response': cfToken
    }, plan)
  }

  const handleChange = useCallback(
    e => {
      const { name, value } = e.target
      setForm({ ...form, [name]: value })
    },
    [form],
  )

  const handleEmail = () => {
    // trigger request for api
    shoppingCart({
      offer: plan.offer_code,
      customer_email: form.cus_email,
      customer_name: form.cus_name,
    })
    // after that update state
    // handleChange(e)
  }

  const handleChangeCheckbox = (e, InputRef) => {
    const { value: installmentValue } = InputRef.current
    const { name, checked } = e.target
    const arrayAux = [...form.custom_offers]
    const findCustomOffer = getCustomOfferIdByCode(form, name)

    if (findCustomOffer === -1) {
      if (checked) {
        const offerSelected = plan?.custom_offers.filter(
          el => el?.code === name,
        )[0]

        const hasOneInstallment =
          offerSelected && Object.keys(offerSelected?.installments).length <= 1

        arrayAux.push({
          code: name,
          installments: hasOneInstallment ? 1 : installmentValue,
        })
      }
    } else {
      if (!checked) {
        arrayAux.splice(findCustomOffer, 1)
      }
    }

    setForm({ ...form, custom_offers: arrayAux })
  }

  const handleChangeOrderBumpInstallments = e => {
    const { name, value } = e.target
    const arrayAux = [...form.custom_offers]
    const findCustomOffer = getCustomOfferIdByCode(form, name)
    if (findCustomOffer !== -1) {
      arrayAux[findCustomOffer].installments = parseInt(value)
    }

    setForm({ ...form, custom_offers: arrayAux })
  }

  const handleInputNumberChange = useCallback(
    e => {
      const { name, value } = e.target

      const clearValue = value.trim().replace(/\D/g, '')
      const re = /^[0-9\b]+$/
      console.log(clearValue)
      if (clearValue === '' || re.test(clearValue)) {
        setForm({ ...form, [name]: clearValue })
      }
    },
    [form],
  )

  return (
    <Content>
      <Box as="form" onSubmit={handleSubmit}>
        {/* PASSO 1 */}
        <Box>
          <LabelForm>
            Passo 1: <b>Informações de Contato</b>
          </LabelForm>
          <InputForm
            name={'cus_name'}
            placeholder={'Nome Completo'}
            handleChange={handleChange}
            value={form.cus_name}
            disabled={profile?.name}
          />
          <InputForm
            name={'cus_email'}
            placeholder={'E-mail'}
            type={'email'}
            handleChange={handleChange}
            onBlur={handleEmail}
            value={form.cus_email}
            disabled={profile?.email}
          />
          <InputForm
            name={'cus_document'}
            placeholder={'CPF ou CNPJ'}
            type={'tel'}
            handleChange={handleInputNumberChange}
            value={form.cus_document}
          />

          <Flex mt={-15} justifyContent="space-between">
            <InputFormMask
              value={form.cus_phone_ddd}
              name={'cus_phone_ddd'}
              placeholder={'DDD'}
              type={'tel'}
              handleChange={handleInputNumberChange}
              width={[1 / 4]}
              mask={'99'}
              disabled={profile?.phone_ddd}
            />

            <InputFormMask
              value={form.cus_phone_number}
              name={'cus_phone_number'}
              placeholder={'Número do celular'}
              type={'tel'}
              handleChange={handleInputNumberChange}
              width={'70%'}
              mask={'999999999'}
              disabled={profile?.phone_number}
            />
          </Flex>
        </Box>

        {/* PASSO 2 */}
        <Box pt={4}>
          <LabelForm>
            Passo 2: <b>Endereço</b>
          </LabelForm>
          <InputFormMask
            name={'cus_zipcode'}
            placeholder={'CEP'}
            type={'text'}
            handleChange={handleChange}
            onBlur={() => getZipCode(form.cus_zipcode)}
            mask={'99999999'}
          />
          {cepNotFonded && (
            <LabelForm>
              <b style={{ color: 'red' }}>{cepNotFonded}</b>
            </LabelForm>
          )}
          <Box hidden={!showCepDetails}>
            <Flex my={-15} justifyContent="space-between">
              <InputForm
                name={'cus_street'}
                placeholder={'Rua'}
                type={'text'}
                handleChange={handleChange}
                value={form.cus_street}
                width={'70%'}
              />

              <InputForm
                name={'cus_street_number'}
                placeholder={'Número'}
                type={'text'}
                handleChange={handleChange}
                width={'25%'}
                value={form.cus_street_number}
              />
            </Flex>

            <InputForm
              name={'cus_neighborhood'}
              placeholder={'Bairro'}
              type={'text'}
              value={form.cus_neighborhood}
              handleChange={handleChange}
            />

            <InputForm
              name={'cus_complementary'}
              placeholder={'Complemento'}
              type={'text'}
              required={false}
              handleChange={handleChange}
              value={form.cus_complementary}
            />
          </Box>
        </Box>

        {/* PASSO 3 */}
        <Box py={4}>
          <LabelForm>
            Passo 3: <b>Informações de Cobrança</b>
          </LabelForm>

          <Text
            fontSize={1}
            fontWeight="normal"
            color="secondary"
            hidden={!isCupomVisible}
            onClick={e => {
              e.preventDefault()
              setIsCupomVisible(!isCupomVisible)
            }}
            style={{
              marginTop: 15,
              textDecoration: 'underline',
              cursor: 'pointer',
              color: '#52575C',
              opacity: 0.65,
            }}
          >
            Possui um cupom de desconto?
          </Text>
          <Box hidden={isCupomVisible}>
            <Flex justifyContent={'space-between'} alignItems={'center'}>
              <InputForm
                required={false}
                placeholder={'Digite o cupom'}
                handleChange={setPromoCode}
              />
              <Box width={[1 / 3]} px={1}>
                <Button
                  width="100%"
                  sx={{
                    backgroundColor: '#3ACF6C',
                    borderRadius: 8,
                    fontFamily: 'Inter',
                  }}
                  onClick={getPromo}
                  disabled={!promo.length > 0}
                >
                  Aplicar
                </Button>
              </Box>
            </Flex>
          </Box>

          <InputGroup
            name={'card_number'}
            placeholder={'Número do cartão'}
            type={'tel'}
            handleChange={handleChange}
            mask={'9999999999999999'}
            value={form.card_number}
          />

          <InputForm
            name={'card_holder_name'}
            placeholder={'Nome do titular'}
            handleChange={handleChange}
            value={form.card_holder_name}
          />
          <Flex my={-15} justifyContent="space-between" width={'70%'}>
            <InputFormMask
              name={'card_expiration_date'}
              placeholder={'MM / AA'}
              type={'tel'}
              handleChange={handleChange}
              width={'45%'}
              mask={'99/99'}
            />

            <InputFormMask
              name={'card_cvv'}
              placeholder={'CVC'}
              type={'tel'}
              handleChange={handleChange}
              width={'45%'}
              mask={'9999'}
            />
          </Flex>
          {plan.installments.length > 1 && (
            <>
              <Box>
                <InputSelect
                  name={'installments'}
                  options={plan.installments}
                  handleChange={handleChange}
                  value={form.installments}
                />
                <Text
                  fontSize={1}
                  fontWeight="normal"
                  color="secondary"
                  style={{
                    color: '#52575C',
                    opacity: 0.65,
                    fontSize: 13,
                    fontFamily: 'Nunito Sans',
                    cursor: 'default',
                    fontStyle: 'italic'
                  }}
                >*Pagamento parcelado com acréscimo de juros.</Text>
              </Box>
            </>
          )}
        </Box>
        {plan.custom_offers.length > 0 && (
          <Flex justifyContent={'space-between'}>
            {/* Aceito os termos e condições */}
            <OrderBump
              plan={plan}
              customOffersSelected={form.custom_offers}
              handleChangeCheckbox={handleChangeCheckbox}
              handleChangeOrderBumpInstallments={
                handleChangeOrderBumpInstallments
              }
            />
          </Flex>
        )}
        <Text
          sx={{
            fontSize: 24,
            fontWeight: 400,
            textAlign: 'left',
            color: '#616877',
          }}
        >
          Detalhamento da compra
        </Text>
        <OrderDetails
          title={plan.name}
          hasOfferOrTrial={
            Boolean(plan?.plan?.trial_days) || Boolean(plan?.offer?.price)
          }
          priceOFFer={renderPriceOffer(plan, form.installments)}
          price={renderPrice(plan, form.installments)}
          offerInterval={plan?.offerInterval}
          offerPeriod={plan?.offer_period}
          disclaimer={Boolean(plan?.initialPrice || plan?.trial)}
        />

        {form.custom_offers.length > 0 && (
          <>
            {form.custom_offers.map((offer, index) => (
              <div key={`OrderDetails_${index}`}>
                <OrderDetails
                  title={getCustomOffer(plan, offer.code)?.title}
                  priceOFFer={orderDetailsPrice(plan, offer)}
                />
              </div>
            ))}
          </>
        )}

        <Flex justifyContent={'space-between'}>
          {/* Aceito os termos e condições */}
          {/* <FormGroup>
            <FormControlLabel 
              sx={{
                fontSize: 12
              }}
              control={
                <Checkbox 
                  id='terms_of_use' 
                  name='terms_of_use' 
                  onChange={handleChange} 
                />
              }
              label="Sim, eu aceito os termos e condições de uso" 
            />
          </FormGroup> */}
          <Text
            fontSize={1}
            fontWeight="normal"
            color="secondary"
            style={{
              marginTop: 10,
              marginBottom: 20,
              color: '#52575C',
              opacity: 0.65,
              fontSize: 14,
              fontFamily: 'Nunito Sans',
              cursor: 'default',
            }}
          >
            Ao clicar em Comprar você aceita nossos{' '}
            <a
              href="https://makefunnels.com.br/termos-de-uso"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: '#52575C',
              }}
            >
              Termos de Uso
            </a>
          </Text>
        </Flex>

        <Flex my={3} justifyContent={'center'}>
          <div id="turnstileEl" data-theme="light"></div>
        </Flex>
        {/* BUTTON */}
        <Box mt={[4, 4, 0]}>
          <Button
            width="100%"
            sx={{
              paddingTop: 15,
              paddingBottom: 15,
              backgroundColor: '#3ACF6C',
              borderRadius: 8,
              fontFamily: 'Inter',
              fontWeight: 600,
              cursor: 'pointer',
            }}
          >
            Comprar Agora
          </Button>
        </Box>
      </Box>
      <Flex mt={3} justifyContent={'center'}>
        <CardIcon />
      </Flex>
    </Content >
  )
}

export default Form
