import {styled} from '@mui/material/styles'

export const Content = styled('div')(({theme}) => ({
  background: '#f9f9f9',
  borderRadius: '8px',
  boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.25)',
  padding: '25px 23px',
  flex: '1',
}))

export const Disclaimer = styled('span')(() => ({
  color: '#25282B',
  fontFamily: 'Nunito Sans',
  fontSize: 15,
}))
