import { useQuery } from './useQuery';

export function useInitializeForm({ profile, initialState }) {
    const query = useQuery()

    if (profile) {
        const profileInfo = {
            cus_name: profile?.name,
            cus_email: profile?.email,
            cus_phone_ddd: profile?.phone_ddd,
            cus_phone_number: profile?.phone_number,
        }

        return ({
            ...initialState,
            ...profileInfo
        })
    }

    const form = {
        cus_name: query.get('name'),
        cus_email: query.get('email'),
        cus_document: query.get('doc'),
        cus_phone_ddd: query.get('phoneddd'),
        cus_phone_number: query.get('phonenumber'),
    }


    return ({
        ...initialState,
        ...form,
    })
}