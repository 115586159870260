import React from 'react'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Home from './pages/Home/index.jsx'

function Routes() {
  return (
    <Router>
      <Switch>
        {/* <Route path="" component={PaymentForm} /> */}
        <Route path="" component={Home} />
      </Switch>
    </Router>
  )
}

export default Routes
