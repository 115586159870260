import {styled} from '@mui/material/styles'
import {Box} from 'rebass'

export const Wrapper = styled(Box)(() => ({
    border: '2px solid red',
    borderStyle: 'dashed',
    borderRadius: '0.3rem',
    // padding: '0.8rem',
    marginBottom: '1.2rem',
  }))

  export const Title = styled(Box)(() => ({
    backgroundColor: '#f0f3f7',
    textAlign: 'center',
    padding: '0.5rem',
  }))

  export const Content = styled(Box)(() => ({
    padding: '0.8rem'
  }))

  