import { Container } from '@mui/material'
import { styled } from '@mui/material/styles'
import axios from 'axios'
import Cookies from 'js-cookie'
import pagarme from 'pagarme'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Flex, Text } from 'rebass'
import Swal from 'sweetalert2'
import { ReactComponent as CheckMakefunnelsIcon } from '../../assets/check-makefunnels-round.svg'
import { ReactComponent as SiteSeguroIcon } from '../../assets/site-seguro.svg'
import Features from '../../components/Features'
import Form from '../../components/Form'
import { parseDecimal } from '../../components/Form/utils'
import Header from '../../components/Header'
import Loading from '../../components/Loading'
import { getProfile, subscriptions } from '../../services'

const Title = styled('h6')(({ theme }) => ({
  fontFamily: 'Nunito Sans',
  fontWeight: '700',
  fontSize: 24,
  margin: 0,
  padding: '30px 0px',
  textAlign: 'center',
  width: '100%',
}))
const Disclaimer = styled('h6')(({ theme }) => ({
  fontFamily: 'Nunito Sans',
  fontWeight: '400',
  fontSize: 12,
  margin: 0,
  textAlign: 'left',
  width: '100%',
  color: '#727272',
  [theme.breakpoints.up('sm')]: {
    maxWidth: 900,
  },
}))

const Beneficio = styled('h6')(({ theme }) => ({
  fontFamily: 'Nunito Sans',
  fontWeight: 800,
  fontSize: 18,
  margin: 0,
  textAlign: 'start',
  width: '100%',
  color: '#54C989',
}))

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  flex: 0.7,

  [theme.breakpoints.down('md')]: {
    marginTop: 50,
    width: '100%',
  },
}))

const Wrapper = styled(Flex)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('md')]: {
    width: '70rem',
  },
}))

function Home() {
  const location = useLocation()
  const token = new URLSearchParams(location.search).get('token')

  const [plan, setPlan] = useState({
    name: '',
    price: 0,
    initialPrice: 0,
    offerInterval: '',
    trial: 0,
    installments: [],
    features: [],
    offer_code: '',
    offer_trial_price: '',
    offer_initial_interval: '',
    offer_initial_period: '',
    offer: {
      price: 0,
      period: '',
      interval: 0,
    },
    plan: {
      name: '',
      price: 0,
      interval: 0,
      trial_days: 0,
    },
    custom_offers: [],
  })
  const [state, setState] = useState({
    profile: null,
    loading: true,
  })
  const installmentSelected = React.useRef(1)
  const [promoCode, setPromoCode] = useState('')

  const [beneficios, setBeneficios] = useState([
    'GARANTIA INCONDICIONAL DE 30 DIAS',
    'CANCELE QUANDO QUISER',
    'SEM COMPROMISSOS',
  ])

  const loadProfile = async () => {
    try {
      if (!token) return null
      const response = await getProfile(token)
      const {
        data: {
          data: { name, role, phone_ddd, phone_number, email },
        },
      } = response
      return { name, role, phone_ddd, phone_number, email }
    } catch (error) {
      console.info(error)
      return null
    }
  }

  const getInfo = async (path, params) => {
    setState({ ...state, loading: true })
    try {
      const response = await subscriptions(path, params)
      const {
        data: {
          data: {
            plan: { name, features },
            offer_code,
            offer_interval,
            offer_price,
            offer_initial_interval,
            offer_initial_period,
            offer_initial_price,
            offer_trial_days,
            offer_name,
            installments,
            custom_offers,
            ...rest
          },
        },
      } = response

      const profileResponse = await loadProfile()

      setPlan({
        name: offer_name || name,
        features,
        installments: installments,
        offer: {
          price: parseDecimal(offer_initial_price),
          period: offer_initial_period,
          interval: offer_initial_interval,
        },
        plan: {
          name: name,
          price: parseDecimal(offer_price),
          interval: offer_interval,
          trial_days: offer_trial_days,
        },
        price: parseDecimal(offer_price),
        initialPrice: parseDecimal(offer_initial_price),
        offerInterval: offer_interval,
        trial: offer_trial_days,
        offer_code,
        offer_initial_interval,
        offer_initial_period,
        custom_offers,
        ...rest,
      })

      setState({ ...state, profile: profileResponse, loading: false })
      if (offer_interval !== 'ano') {
        setBeneficios(['CANCELE QUANDO QUISER', 'SEM COMPROMISSOS'])
      }
    } catch (err) {
      setState({ ...state, loading: false })
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Não foi possível carregar plano',
        showCancelButton: false,
        showConfirmButton: false,
        showDenyButton: true,
        denyButtonText: `Cancelar`,
      }).then(result => {
        if (result.isDenied || result.dismiss)
          window.location.href = process.env.REACT_APP_REDIRECT_URL
      })
    }
  }

  const getAffiliateId = () => {
    return Cookies.get('mfaff')
  }

  const getOffer = () => {
    const params = queryString.parse(location?.search)

    return Object.keys(params).length && params.offer
      ? params.offer
      : location?.pathname.replace('/', '')
  }

  const getPromo = async e => {
    e.preventDefault()
    if (promoCode.length > 0) return await getInfo('coupons/', promoCode)

    return await getInfo('offers/', getOffer())
  }

  useEffect(() => {
    const params = queryString.parse(location?.search)

    const getPlan = async () => {
      const offer = getOffer()
      await getInfo('offers/', offer)
    }

    getPlan()

    if (params.split) {
      installmentSelected.current = params.split
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePromo = async e => {
    setPromoCode(e.target.value)
  }

  // SUBMIT
  const handleSubmit = (form, plan) => {
    setState({ ...state, loading: true })

    const {
      card_holder_name,
      card_expiration_date,
      card_number,
      card_cvv,
      ...rest
    } = form
    const card = { card_holder_name, card_expiration_date, card_number, card_cvv }
    const customer = rest
    const cardValidations = pagarme.validate({ card: card })

    if (form.customerComplementary !== '') {
      customer.cus_complementary = form.customerComplementary
    }

    const affiliateID = getAffiliateId()
    if (affiliateID) {
      customer.mfaff = affiliateID
    }

    if (!cardValidations.card.card_number) {
      setState({ ...state, loading: false })
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Número do cartão incorreto',
        showCancelButton: false,
        showConfirmButton: false,
        showDenyButton: true,
        denyButtonText: `Cancelar`,
      }).then(result => {
        if (result.isDenied) return
      })
    }

    pagarme.client
      .connect({ encryption_key: process.env.REACT_APP_ENCRYPTION_KEY })
      .then(client => client.security.encrypt(card))
      .then(card_hash => {
        const data = {
          payment_platform: 1,
          card_hash: card_hash,
          ...customer,
          terms_of_use: form.terms_of_use,
          // plan_code: plan.offer_code,
        }

        const request = {
          method: 'post',
        }

        if (token) {
          request.url = process.env.REACT_APP_API_URL + '/subscription/change'
          request.headers = {
            Authorization: `Bearer ${token}`,
          }
          data.offer_code = plan.offer_code
          request.data = data
        } else {
          request.url = process.env.REACT_APP_API_URL + '/subscriptions/pay'
          data.plan_code = plan.offer_code
          request.data = data
        }

        axios(request)
          .then(res => {
            window.fbq('track', 'Purchase', {
              value: plan.price,
              currency: 'BRL',
            })
            window.location.href = 'https://makefunnels.com.br/confirmado'
            setState({ ...state, loading: false })
          })
          .catch(err => {
            setState({ ...state, loading: false })
            let errorText = ''
            const {
              response: {
                data: { error },
              },
            } = err
            if (error) {
              errorText = error
              if (typeof error !== 'string') {
                const errorsKeys = Object.keys(error)
                errorText = error[errorsKeys[0]]
              }
            }
            if (!errorText) {
              errorText =
                'Houve um problema nas informações passadas. Por favor revise seus dados. '
            }

            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: errorText,
              showCancelButton: false,
              showConfirmButton: false,
              showDenyButton: true,
              denyButtonText: `Cancelar`,
            }).then(result => {
              if (result.isDenied) return
            })
          })
      })
  }

  return (
    <>
      {state.loading ? (
        <Loading />
      ) : (
        <>
          <Header profile={state.profile} />
          <Flex flexDirection="column" py={10} width={'100%'}>
            <Title>Finalize sua compra!</Title>
            <Wrapper
              alignItems="flex-start"
              justifyContent="center"
              flexWrap={'wrap'}
              padding={2}
              margin={'auto'}
            >
              <Form
                plan={plan}
                installmentSelected={installmentSelected}
                getPromo={getPromo}
                promo={promoCode}
                setPromoCode={handlePromo}
                submit={handleSubmit}
                profile={state.profile}
              />

              <Content>
                <Features features={plan.features} name={plan.name} />
                <Box py={3} px={3}>
                  {beneficios.map((item, index) => (
                    <Flex
                      paddingLeft={'20px'}
                      alignItems={'start'}
                      mt={3}
                      key={index}
                    >
                      <CheckMakefunnelsIcon
                        style={{
                          fontSize: 22,
                          fontWeight: 800,
                          color: '#54C989',
                          marginRight: '10px',
                        }}
                      />{' '}
                      <Beneficio>{item}</Beneficio>
                    </Flex>
                  ))}
                </Box>
              </Content>
            </Wrapper>
          </Flex>

          <Container style={{ marginBottom: 25 }}>
            <Flex
              width="100%"
              flexDirection="column"
              alignItems="center"
              textAlign="center"
              justifyContent="center"
            >
              <SiteSeguroIcon style={{ marginBottom: 12, marginTop: 12 }} />

              <Disclaimer>
                Este é um serviço de assinatura com recorrência e que será
                renovada automaticamente, ao final de cada ciclo (mensal,
                semestral ou anual - de acordo com o informado no plano), caso
                não seja cancelada. É possível cancelar a renovação automática
                da assinatura antes do início do próximo ciclo de faturamento.
                Para tal, é preciso cancelar dentro do seu acesso à plataforma.
                Nesse caso, o cancelamento é automático e não necessita de
                contato humano. Alternativamente, é possível enviar um e-mail para{' '}
                <b>suporte@makefunnels.com.br</b>{' '}solicitando o cancelamento da
                renovação do plano com pelo menos 2 dias úteis de antecedência
                do início do próximo ciclo de faturamento, informando o endereço
                de email cadastrado na conta. Não há multas, taxas ou contratos
                de compromisso, você pode cancelar, sem custo adicional e quando
                quiser, sua assinatura. Para mais informações e detalhes, acesse
                nosso{' '}
                <a
                  href="https://makefunnels.com.br/termos-de-uso"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: '#52575C',
                    opacity: 0.65,
                  }}
                >
                  Termos de uso e serviço
                </a>
                .
              </Disclaimer>

              <Box mt={3}>
                <Text color="#727272" fontSize={1} fontWeight="normal" pb={2}>
                  Copyright ⓒ {new Date().getFullYear()}, Makefunnels - All
                  Rights Reserved
                </Text>
              </Box>
            </Flex>
          </Container>
        </>
      )}
    </>
  )
}

export default Home
