import {styled} from '@mui/material/styles'
import React from 'react'
import {Box, Flex} from 'rebass'
import CheckIcon from '@mui/icons-material/Check'

const Content = styled('div')(({theme}) => ({
  background: '#f9f9f9',
  borderRadius: '8px',
  boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.25)',
  padding: '20px 23px',      
  marginLeft: 30,
  [theme.breakpoints.down('md')]: {
    marginLeft: 0,
    width: 'auto',
  },
}))

const Title = styled('span')(theme => ({
  fontSize: 26,
  fontWeight: 700,
  fontFamily: 'Nunito Sans',
  color: '#202123',
}))
const Detail = styled('span')(theme => ({
  fontSize: 17,
  fontWeight: 400,
  fontFamily: 'Nunito Sans',
  color: 'rgb(33, 37, 41)',
}))

const Divider = styled('hr')(theme => ({
  borderTop: '2px solid rgba(0, 0, 0, 0.1)',
}))

const UNLIMITED = 'ilimitados'

function Features({features, name}) {
  return (
    <Content>
      <Flex justifyContent={'center'}>
        <Title>{name}</Title>
      </Flex>
      <Divider />

      {features && features.length ? (
        features.map((item, index) => {
          const {name, value} = item
          let val = value
          if (value === 'U') val = UNLIMITED
          if (value === 'Y') val = ''
          return (
            <Flex alignItems={'center'} my={2} key={index}>
              <CheckIcon
                sx={{
                  fontSize: 32,
                  fontWeight: 800,
                  color: 'rgb(60, 205, 0)',
                  marginRight: '16px',
                }}
              />
              <Detail>
                {val === UNLIMITED ? (
                  <>
                    {name} <b>{val}</b>
                  </>
                ) : (
                  <>
                    <b>{val}</b> {name}
                  </>
                )}
              </Detail>
            </Flex>
          )
        })
      ) : (
        <Box></Box>
      )}
    </Content>
  )
}

export default Features
