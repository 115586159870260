import { MONTH } from "../../constants"

export const parseDecimal = value =>
  value ? (value / 100).toFixed(2).replace('.', ',') : null

export const getCustomOffer = (plan, code) => {
  const customOfferId =
    plan.custom_offers && plan.custom_offers.findIndex(el => el?.code === code)
  if (customOfferId === -1) return null

  return plan.custom_offers[customOfferId]
}

export const getCustomOfferIdByCode = (form, code) => {
  const arrayAux = [...form.custom_offers]
  return arrayAux.findIndex(el => el?.code === code)
}

export const renderPriceOffer = (plan, installmentValue) => {
  let price = ''
  const _plan = plan.plan
  const offer = plan.offer

  if (_plan.trial_days) {
    price = `Primeiros ${_plan.trial_days} dias grátis`
  } else if (offer.price) {
    const prop = offer.period ? offer : plan
    const data = prop
    if (data.period === 1) price = `Primeiro mês por R$ ${offer.price}`
    else price = `Primeiros ${data.period} ${data.interval} por R$${data.price}`
  } else if (plan.installments.length > 1) {
    const installmentPrice = renderInstallmentPrice(
      plan.installments,
      installmentValue,
      plan.price
    )
    price = `R$ ${installmentPrice}`
  } else {
    price = `R$ ${plan.price}`
  }

  return price
}

export const renderPrice = (plan, installmentValue) => (
  plan.offerInterval != MONTH ? renderInstallmentPrice(
    plan.installments,
    installmentValue,
    plan.price,
  ) : plan?.price
)

export const renderInstallmentPrice = (
  installments,
  installmentValue,
  fullPrice,
) => {
  if (parseInt(installmentValue) === 1) return `${fullPrice}`
  const installment =
    installments &&
    installments.filter(el => el.installments === parseInt(installmentValue))
  return installment && (installment.length ? installment[0].label : '')
}

export const orderDetailsPrice = (plan, offer) => {
  const customOffer = getCustomOffer(plan, offer.code)
  const price = customOffer.price / 100

  const installments = customOffer?.installments
  return installments?.length > 0
    ? renderInstallmentPrice(installments, offer.installments, price)
    : `R$ ${normalizePrice(customOffer.price / 100)}`
}

export const normalizePrice = price =>
  Number.parseFloat(price).toFixed(2).replace('.', ',')
