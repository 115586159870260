import {styled} from '@mui/material/styles'

const Label = styled('span')(({theme}) => ({
  fontFamily: 'Nunito Sans',
  color: '#52575C',
  width: '100%',
  fontSize: 17,
}))

export default Label
