import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import React from 'react'
import { ReactComponent as Logo } from '../assets/logo.svg'

function Header({ profile }) {
  return (
    <AppBar position="static" color="primary" py={2} enableColorOnDark>
      <Toolbar style={{ background: 'white', justifyContent: 'space-between', alignItems: 'center' }}>
        <Logo /> {profile && <div><Typography sx={{ color: '#333', fontFamily: 'Nunito Sans', fontWeight: 'bold' }}>Olá, {profile.name}</Typography></div>}
      </Toolbar>
    </AppBar>
  )
}

export default Header
