// module.exports = require('./services')
const useMocks = new URLSearchParams(window.location.search).get('useMocks')

// module.exports = require('./services')

if (Boolean(useMocks) || process.env.REACT_APP_MOCK) {
  module.exports = require('./mocks')
} else {
  module.exports = require('./services')
}
