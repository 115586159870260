import axios from 'axios'

const _axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

export const subscriptions = (path, params) =>
  _axios({
    method: 'get',
    headers: {
      'Accept-Language': 'pt_BR',
    },
    url: '/subscriptions/' + path + params,
  })

export const getProfile = (token) =>
  _axios({
    method: 'get',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
    url: "/auth/me"
  })
