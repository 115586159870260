import { Input } from '@rebass/forms'
import React from 'react'
import InputMask from 'react-input-mask'

function InputForm({
  name,
  placeholder,
  type,
  value,
  handleChange,
  width,
  required = true,
  disabled,
  ...rest
}) {
  return (
    <Input
      id={name}
      type={type ? type : 'text'}
      name={name}
      placeholder={placeholder}
      autoComplete={name}
      value={value}
      required={required}
      onChange={handleChange}
      width={width || '100%'}
      style={{
        border: '2px solid #EDF0F7',
        borderRadius: 8,
        fontFamily: 'Nunito Sans',
        color: '#52575C',
        fontSize: 15,
        margin: '15px 0px',
        paddingLeft: '20px',
        backgroundColor: disabled ? 'rgb(82 87 92 / 18%)' : 'transparent'
      }}
      disabled={disabled}
      {...rest}
    />
  )
}

export function InputFormMask({
  name,
  placeholder,
  type,
  value,
  handleChange,
  placeholderMask,
  mask,
  width,
  onBlur,
  disabled
}) {
  return (
    <InputMask
      mask={mask}
      onChange={handleChange}
      onBlur={onBlur || null}
      maskPlaceholder={placeholderMask ? placeholderMask : null}
      value={value}
      disabled={disabled}
    >
      <Input
        id={name}
        type={type ? type : 'text'}
        name={name}
        placeholder={placeholder}
        autoComplete={name}
        required
        onChange={handleChange}
        width={width || '100%'}
        sx={{
          border: '2px solid #EDF0F7',
          borderRadius: '8px',
          fontFamily: 'Nunito Sans',
          fontSize: '15px',
          margin: '15px 0px',
          paddingLeft: '20px',
          color: '#52575C',
          backgroundColor: disabled ? 'rgb(82 87 92 / 18%)' : 'transparent'
        }}
      />
    </InputMask>
  )
}

export default InputForm
